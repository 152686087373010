import axios from '@/lib/axios-instance';
import { APIUrlGenerator } from '@/lib/helper';

import { getDownloadLinkType, SearchType } from '@/components/shared/services/types';

import { API_ROUTES } from '@/constant/routes';

export async function getSearch(props?: SearchType) {
  try {
    const {
      data: { payload }
    }: { data: { payload: any } } = await axios.get(
      APIUrlGenerator(API_ROUTES.GET_SEARCH, props?.params)
    );
    return payload;
  } catch (error) {
    throw error;
  }
}

export async function getIconicProducts(props?: SearchType) {
  try {
    const {
      data: { payload }
    }: { data: { payload: any } } = await axios.get(
      APIUrlGenerator(API_ROUTES.GET_ICONIC_PRODUCTS, props?.params)
    );
    return payload;
  } catch (error) {
    throw error;
  }
}

export async function getDownloadLink(productId: getDownloadLinkType) {
  try {
    const {
      data: { payload }
    }: { data: { payload: any } } = await axios.get(
      APIUrlGenerator(API_ROUTES.GET_FREE_DOWNLOAD_LINK(productId))
    );
    return payload;
  } catch (error) {
    throw error;
  }
}

export async function getStoreDiscount(slug: string) {
  try {
    const {
      data: { payload }
    }: { data: { payload: any } } = await axios.get(
      APIUrlGenerator(API_ROUTES.GET_STORE_DISCOUNT(slug))
    );
    return payload;
  } catch (error) {
    return null;
  }
}

export async function postLogout() {
  try {
    const {
      data: { payload }
    }: { data: { payload: any } } = await axios.get(
      APIUrlGenerator(API_ROUTES.LOG_OUT)
    );
    return payload;
  } catch (error) {
    throw error;
  }
}
