import Skeleton from 'react-loading-skeleton';

import cn from '@/lib/clsxm';
import { priceFormatter } from '@/lib/helper';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import { TEXT_TYPE } from '@/components/@base/typography/text/type';
import DiscountLabel from '@/components/shared/components/price-view/discount-label';
import Props from '@/components/shared/components/price-view/type';

import { useAddonsStore } from '@/store/addons.store';

import localization from '@/constant/localization';

export default function PriceView({
  price,
  discount = 0,
  lastPrice,
  saleEndedAt = Date.now(),
  showDiscountLabel = false,
  showDiscountWithoutExpiration = false,
  isDark = false,
  className,
  priceWrapperClassName = '',
  priceClassName = '',
  lastPriceClassName = '',
  discountLabelClassName = '',
  priceCurrencyWrapperClassName = '',
  currencyClassName = '',
  isLoading = false,
  sellable = true,
  regularPrice,
}: Props) {
  const { addons, setAddons } = useAddonsStore();
  let subscriptionLifetime = addons.subscription_lifetime?.selected === true;

  const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  const discountIsValid =
    (saleEndedAt > currentTimeInSeconds || showDiscountWithoutExpiration) && discount > 0;
  return (
    <Container className={className}>
      {showDiscountLabel && discountIsValid && (
        <DiscountLabel discount={discount} className={discountLabelClassName} />
      )}
      <Container className={priceWrapperClassName}>
        {sellable &&
          discountIsValid &&
          lastPrice &&
          lastPrice > 0 &&
          (isLoading ? (
            <Skeleton width={65} height={20} />
          ) : (
            <Text
              medium
              className={cn(
                isDark ? 'text-[#BC8D61]' : 'text-[#BCA087]',
                '-mb-1 line-through',
                lastPriceClassName,
              )}
            >
              {subscriptionLifetime ? priceFormatter(regularPrice) : priceFormatter(lastPrice)}
            </Text>
          ))}
        <Container center className={cn('justify-start gap-1', priceCurrencyWrapperClassName)}>
          {isLoading ? (
            <Skeleton width={65} height={20} />
          ) : (
            <Text
              size={isDark ? SIZE_ENUM.LG : SIZE_ENUM.XL}
              type={TEXT_TYPE.SPAN}
              bold
              className={cn(isDark ? 'text-[#FF7B01]' : 'text-[#424244]', priceClassName)}
            >
              {sellable ? priceFormatter(price) : localization.unavailable}
            </Text>
          )}
          {sellable && !!price && price > 0 && (
            <Text
              type={TEXT_TYPE.SPAN}
              className={cn(
                'text-[13px]',
                isDark ? 'text-[#8C8C8C]' : 'text-[#76767C]',
                currencyClassName,
              )}
            >
              {localization.IRCurrency}
            </Text>
          )}
        </Container>
      </Container>
    </Container>
  );
}
