import { getCookie } from '@/lib/cookies';


const waitForDataLayer = (timeout = 3000, interval = 100) => {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();

    const checkDataLayer = () => {
      if (window.dataLayer && Array.isArray(window.dataLayer)) {
        resolve(window.dataLayer);
      } else if (Date.now() - startTime > timeout) {
        reject(new Error('dataLayer did not become available within the timeout period.'));
      } else {
        setTimeout(checkDataLayer, interval);
      }
    };

    checkDataLayer();
  });
};

const handleGA4Event = async (event, items, extraInfo = {}) => {
    const isExistGA4Cookie = getCookie('cookie_consent') === 'accepted';
    if (isExistGA4Cookie || process.env.NEXT_PUBLIC_DISABLE_COOKIE_PERMISSION==='true') {
      try {
        await waitForDataLayer();
        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push({
          event,
          ecommerce: {
            ...extraInfo,
            items
          }
        });
      } catch (error) {
        console.error('Error handling GA4 event:', error);
      }
    }
  }
;

export default handleGA4Event;

export const setUserAttr = (data) => {
  try {
    let raw = {
      userId: data?.mobile,
      firstName: data?.first_name,
      lastName: data?.last_name,
      mobile: data?.mobile,
      email: data?.email,
      user_type: data?.user_type,
      national_id: data?.national_id,
      customer_type: data?.customer_type
    };
    zebline.user.setAttributes(raw);
  } catch (error) {
    console.error('Error setting user attributes:', error);
    localStorage.clear();
    setTimeout(() => setUserAttr(data), 1000);
  }
};

export const eventTracker = (userId, eventName, eventTime, eventData) => {
  try {
    if (typeof zebline === 'undefined' || !zebline?.event?.track) {
      setTimeout(() => {
        if (userId && eventName && eventData) {
          eventTracker(userId, eventName, null, eventData);
        }
      }, 1000);
    } else {
      zebline.event.track(eventName, { userId, ...eventData });
    }
  } catch (error) {
    console.error('Error tracking event:', error);
  }
};
