'use client';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { usePathname } from 'next/navigation';
import { RiDownload2Fill } from 'react-icons/ri';
import { TbShoppingCart } from 'react-icons/tb';
import AddToCartModal from 'src/components/shared/components/add-to-cart-button/add-to-cart-modal';

import cn from '@/lib/clsxm';
import { APIErrorResponseGenerator } from '@/lib/helper';
import handleGA4Event, { eventTracker } from '@/lib/service-workers';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Button from '@/components/@base/button';
import Container from '@/components/@base/container';
import { showToast } from '@/components/@base/toast/show-toast';
import { Text } from '@/components/@base/typography';
import XImage from '@/components/@base/x-image';
import Auth from '@/components/app/auth/components';
import { usePostAddToCart } from '@/components/app/cart/services/hooks';
import { useGetProfile } from '@/components/layout/services/hooks';
import Props from '@/components/shared/components/add-to-cart-button/type';
import { useGetFreeDownloadLink } from '@/components/shared/services/hooks';

import { useAddonsStore } from '@/store/addons.store';
import useCommonModalStore from '@/store/common-modal-store';
import { usePrerequisitesStore } from '@/store/prerequisites.store';

import { DATA_CY_ADD_TO_CARD_BUTTON } from '@/constant/cypress';
import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';
import imagesList from '@/constant/images-list';
import localization, { pageLevelLocalization } from '@/constant/localization';
import { GET_CART } from '@/constant/query-key';

const { product: productLocalization, cart: cartLocalization } = pageLevelLocalization;

export default function AddToCartButton({
  productId,
  demoId,
  children,
  size,
  disabled,
  slug,
  className,
  isFree = false,
  ...props
}: Props) {
  const { setShow } = useCommonModalStore();
  const queryClient = useQueryClient();
  const { profile } = useGetProfile();
  const { mutate, isPending } = usePostAddToCart();
  const { addons, setAddons } = useAddonsStore();
  const selectedAddOns = Object.keys(addons).filter((item) => addons[item].selected);
  const { prerequisites } = usePrerequisitesStore();
  const unselectedPrerequisites = Object.keys(prerequisites).filter(
    (id) => !prerequisites[id].selected,
  );
  const pathname = usePathname();
  const { isAuthenticated } = useGetProfile();
  const {
    data: downloadLink,
    refetch: refetchDownloadLink,
    isLoading: isGetDownloadLinkPending,
  } = useGetFreeDownloadLink(productId);

  const handleFreeDownload = () => {
    const fetchDownloadLink = async () => {
      refetchDownloadLink()
        .then((response) => {
          if (response.data) {
            const link = document.createElement('a');
            link.href = response.data;
            link.setAttribute('download', ''); // Optional: you can specify a default filename here
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            showToast({ message: 'Download link is not available', type: 'error' });
          }
        })
        .catch((error) => {
          //TODO: handle error appropriately
          console.log(error);
        });
    };

    if (isAuthenticated) {
      fetchDownloadLink();
    } else {
      handleAuthModal();
    }
  };
  const handleAddToCart = () => {
    mutate(
      {
        id: productId,
        slug: selectedAddOns,
        demo_id: demoId,
        prerequisite: unselectedPrerequisites,
      },
      {
        onError: (e) => {
          if (e instanceof AxiosError) showToast(APIErrorResponseGenerator(e));
        },
        onSuccess: (res) => {
          queryClient.setQueryData([GET_CART], res);
          eventTracker(profile?.mobile, 'add_to_basket', new Date().toISOString(), {
            productId,
          });
          const product = res?.products?.find((item) => item?.id === productId);
          handleGA4Event(
            'add_to_cart',
            [
              {
                item_id: productId,
                item_name: product?.title,
                price: product?.price,
                index: 0,
                quantity: 1,
                affiliation: product?.store?.slug,
                discount: product?.discount,
              },
            ],
            {
              currency: 'USD',
              value: res?.total,
            },
          );
          if (pathname !== '/') {
            handleOpenModal();
          } else {
            showToast({ message: cartLocalization.productSuccessfullyAdded, type: 'success' });
          }
        },
      },
    );
  };

  const handleOpenModal = () => {
    setShow(true, {
      DialogPanelProps: { className: 'rounded-xl overflow-visible !p-0 max-w-[600px]' },
      DialogProps: {
        onClose: () => {
          setAddons({});
          setShow(false);
        },
      },
      Head() {
        return (
          <Container className='p-1.5'>
            <Container
              center
              className='flex-col rounded-lg bg-[linear-gradient(180deg,_#81cf3312_0%,_#ffffff00_43.89%)] py-[26px]'
            >
              <XImage
                src={imagesList.icons.addToCartIcon}
                alt={productLocalization.productAddedToCart}
              />
              <Text bold size={SIZE_ENUM.LG} className='text-[#424244]'>
                {productLocalization.productAddedToCart}
              </Text>
            </Container>
          </Container>
        );
      },
      Body() {
        return <AddToCartModal slug={slug} productId={productId} demoId={demoId} />;
      },
      Action() {
        return (
          <Container center className='justify-between gap-4 p-4 pt-0 md:p-[30px] md:pt-3.5'>
            <Button
              className='h-[52px] w-full max-w-[184px] bg-[#F7F8F9] text-[15px] font-bold text-[#878F9B]'
              onClick={handleCloseModal}
            >
              {productLocalization.return}
            </Button>
            <a href='/cart' className='w-[184px]'>
              <Button
                className='h-[52px] w-full text-[15px] font-bold text-white'
                Icon={<TbShoppingCart color={ICON_COLOR.white} size={ICON_SIZE.sm} />}
              >
                {productLocalization.continueShopping}
              </Button>
            </a>
          </Container>
        );
      },
    });
  };
  const handleCloseModal = () => {
    setShow(false);
    setAddons({}); //clear the selected addons when closing the modal
  };
  const handleAuthModal = () => {
    setShow(true, {
      DialogPanelProps: {
        className: 'rounded-xl overflow-hidden !shadow-none  !p-0 !bg-transparent ',
      },
      Body() {
        return <Auth />;
      },
    });
  };

  return isFree ? (
    <Button
      size={size}
      isLoading={isGetDownloadLinkPending}
      disabled={disabled}
      data-cy={DATA_CY_ADD_TO_CARD_BUTTON}
      onClick={handleFreeDownload}
      className={cn('gap-2.5 hover:bg-[#EB8800]', className)}
      Icon={<RiDownload2Fill color={ICON_COLOR.white} size={ICON_SIZE.sm} />}
    >
      {localization.freeDownload}
    </Button>
  ) : (
    <Button
      size={size}
      isLoading={isPending}
      disabled={disabled}
      data-cy={DATA_CY_ADD_TO_CARD_BUTTON}
      onClick={handleAddToCart}
      className={cn('gap-2.5 hover:bg-[#EB8800]', className)}
      {...props}
    >
      {children}
    </Button>
  );
}
