type Props = {
  slug: string;
  productId: string;
  demoId?: string;
};

export type SelectedAddOns = {
  [key: string]: { selected: boolean; price: number };
};

export enum ADDONS_ENUM {
  EXTRA_SUPPORT = 'extra-support',
  INSTALLATION = 'installation',
  REPEAT_PURCHASE = 'extra_license',
  SUBSCRIPTION_LIFETIME = 'subscription_lifetime',
  SUBSCRIPTION_ONE_YEAR = 'subscription_one_year',
}

export default Props;
