export const DATA_CY_HERO = 'data-cy-hero';
export const DATA_CY_STORY = 'data-cy-story';
export const DATA_CY_STORY_SLIDER = 'data-cy-story-slider';
export const DATA_CY_ADDS_BANNER = 'data-cy-adds-banner';
export const DATA_CY_BUNDLES = 'data-cy-bundles';
export const DATA_CY_PRODUCTS = 'data-cy-products';
export const DATA_CY_PRODUCT_BANNER = 'data-cy-product-banner';
export const DATA_CY_BEST_SELLER = 'data-cy-best-seller';
export const DATA_CY_BEST_PRODUCTS = 'data-cy-best-products';
export const DATA_CY_BLOGS = 'data-cy-blogs';
export const DATA_CY_COURSES = 'data-cy-courses';
export const DATA_CY_COURSE = 'data-cy-course';
export const DATA_CY_BUNDLES_SLIDER = 'data-cy-bundles-slider';
export const DATA_CY_BLOGS_SLIDER = 'data-cy-blogs-slider';
export const DATA_CY_BEST_SELLER_PRODUCTS = 'data-cy-best-seller-products';
export const DATTA_CY_MENU_BUTTON = 'data-cy-menu-button';
export const DATTA_CY_MENU_ITEM_BUTTON = 'data-cy-menu-item-button';
export const DATA_CY_MENU_CLOSE_BUTTON = 'data-cy-menu-close-button';
export const DATA_CY_SEARCH_INPUT = 'data-cy-search-input';
export const DATA_CY_SEARCH_POP_UP = 'data-cy-search-pop-up';
export const DATA_CY_HEADER = 'data-cy-header';
export const DATA_CY_ADD_TO_CARD_BUTTON = 'data-cy-add-to-card-button';
export const DATA_CY_PRODUCT_CARD = 'data-cy-product-card';
export const DATA_CY_PRODUCT_CONTENT_CARD = 'data-cy-product-content-card';
export const DATA_CY_PRODUCT_LIST_CARD = 'data-cy-product-list-card';
export const DATA_CY_MENU_CONTAINER = 'data-cy-menu-container';
export const DATA_CY_FOOTER_ACHIEVEMENTS = 'data-cy-footer-achievements';
export const DATA_CY_FOOTER_SOCIAL_MEDIA = 'data-cy-footer-social_media';
export const DATA_CY_FOOTER_MENU = 'data-cy-footer-menu';
export const DATA_CY_FOOTER_ABOUT_US = 'data-cy-footer-about-us';
export const DATA_CY_FOOTER_POLICY = 'data-cy-footer-policy';
export const DATA_CY_FOOTER_NEWSLETTER = 'data-cy-footer-newsletter';
export const DATA_CY_FOOTER_CONTACT = 'data-cy-footer-contact';
export const DATA_CY_FOOTER_SUB_MENU = 'data-cy-footer-sub-menu';
export const DATA_CY_FOOTER_MENU_BUTTON = 'data-cy-footer-menu-button';
export const DATA_CY_INFO = 'data-cy-info';
export const DATA_CY_FILTER_IN_MOBILE = 'data-cy-filter-in-mobile';
export const DATA_CY_FILTER_IN_DESKTOP = 'data-cy-filter-in-desktop';
export const DATA_CY_SORT_CONTAINER = 'data-cy-sort-container';
export const DATA_CY_ICON_TEXT_COMBO = 'data-cy-icon-text-combo';
export const DATA_CY_ARCHIVE_SORT_ITEM_CONTAINER = 'data-cy-archive-sort-item-container';
export const DATA_CY_ARCHIVE_SORT_ITEM = 'data-cy-archive-sort-item';
export const DATA_CY_ARCHIVE_FAQ = 'data-cy-archive-faq';
export const DATA_CY_ARCHIVE_DESCRIPTION = 'data-cy-archive-description';
export const DATA_CY_BREAD_CRUMB = 'data-cy-bread-crumb';
export const DATA_CY_VIEW_MODE_GRID = 'data-cy-view-mode-grid';
export const DATA_CY_VIEW_MODE_LIST = 'data-cy-view-mode-list';
export const DATA_CY_FILTER_POP_UP = 'data-cy-filter-pop-up';
export const DATA_CY_SHOW_MORE_BUTTON = 'data-cy-show-more-button';
export const DATA_CY_FAQ_ACCORDION_BUTTON = 'data-cy-faq-accordion-button';
export const DATA_CY_FAQ_ACCORDION_PANEL = 'data-cy-faq-accordion-panel';
export const DATA_CY_LOADING = 'data-cy-loading';
export const DATA_CY_CHECK_BOX_ITEM = 'data-cy-check-box-item';
export const DATA_CY_VIEW_SELECTOR = 'data-cy-view-selector';
export const DATA_CY_STYLISH_ACCORDION_BUTTON = 'data-cy-stylish-accordion-button';
export const DATA_CY_STYLISH_ACCORDION_BUTTON_MINUS_ICON =
  'data-cy-stylish-accordion-button-minus_icon';
export const DATA_CY_STYLISH_ACCORDION_BUTTON_PLUS_ICON =
  'data-cy-stylish-accordion-button-minus-icon';
export const DATA_CY_STYLISH_ACCORDION_PANEL = 'data-cy-stylish-accordion-panel';
