import axios, { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';

import axiosWithHeaders from '@/lib/axios-instance';
import { APIUrlGenerator } from '@/lib/helper';

import {
  GetAddOnsInfoType,
  GetCommentListType,
  GetDownloadLinkType,
  GetProductType,
  PostCommentType,
  PostFavoritesType,
  PostRateType,
} from '@/components/app/product/services/type';

import { API_ROUTES } from '@/constant/routes';
import AddToCartModel from '@/models/add.to.cart.model';
import BundleModel from '@/models/bundle.model';
import CommentModel from '@/models/comment.model';
import ProductModel from '@/models/product.model';

export async function getSingleProduct(slug: GetProductType = '') {
  const {
    data: { payload },
  }: { data: { payload: ProductModel | BundleModel } } = await axiosWithHeaders.get(
    APIUrlGenerator(API_ROUTES.GET_SINGLE_PRODUCT(slug)),
  );
  return payload;
}

export async function getCommentList({ slug = '', filters, page }: GetCommentListType) {
  const COMMENTS_PER_PAGE = 12;

  const response: {
    data: { payload: CommentModel[] };
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
  } = await axios.get(APIUrlGenerator(API_ROUTES.GET_COMMENT_LIST(slug)), {
    params: { ...filters, page, 'per-page': COMMENTS_PER_PAGE },
  });

  const currentPage = parseInt(response.headers['x-pagination-current-page'], 10);
  const totalProducts = parseInt(response.headers['x-pagination-total-count'], 10);
  const totalPages = Math.ceil(totalProducts / COMMENTS_PER_PAGE);

  return {
    comments: response.data.payload,
    previousCursor: currentPage > 1 ? currentPage - 1 : null,
    nextCursor: currentPage < totalPages ? currentPage + 1 : null,
    currentPage: currentPage,
    totalPages: totalPages,
  };
}

export async function getAddOnsInfo({ slug, demoId, purchaseHistory,rate }: GetAddOnsInfoType) {
  const {
    data: { payload },
  }: { data: { payload: AddToCartModel } } = await axiosWithHeaders.get(
    APIUrlGenerator(API_ROUTES.GET_ADD_ONS_INFO(slug), {
      demo_id: demoId,
      purchase_history: purchaseHistory,
      rate: rate,
    }),
  );
  return payload;
}

export async function getDownloadLink(id: GetDownloadLinkType) {
  const {
    data: { payload },
  } = await axiosWithHeaders.get(APIUrlGenerator(API_ROUTES.GET_DOWNLOAD_LINK(id)));

  return payload;
}

export async function postComment(body: PostCommentType) {
  const { data } = await axiosWithHeaders.post(APIUrlGenerator(API_ROUTES.POST_COMMENT), body);
  return data;
}

export async function acceptAffiliateRules() {
  const { data } = await axiosWithHeaders.get(
    APIUrlGenerator(API_ROUTES.GET_ACCEPT_AFFILIATE_RULES),
  );
  return data;
}

export async function postFavorites(body: PostFavoritesType) {
  const { data } = await axiosWithHeaders.post(
    APIUrlGenerator(API_ROUTES.POST_FAVORITES),
    body,
  );
  return data;
}

export async function postRate(body: PostRateType) {
  const { data } = await axiosWithHeaders.post(APIUrlGenerator(API_ROUTES.POST_RATE), body);
  return data;
}
