import axios from '@/lib/axios-instance';
import { APIUrlGenerator } from '@/lib/helper';

import { AddToCartTypes, CheckoutParams } from '@/components/app/cart/services/type';

import { API_ROUTES } from '@/constant/routes';
import CartModel from '@/models/cart.model';
import ErrorResponseModel from '@/models/error-response.model';

export async function getCart() {
  const {
    data: { payload },
  }: { data: { payload: CartModel } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_CART, { path: window.location.pathname }),
  );
  return payload;
}

export async function postAddToCart(body: AddToCartTypes) {
  const {
    data: { payload },
  }: { data: { payload: CartModel } } = await axios.post(
    APIUrlGenerator(API_ROUTES.POST_ADD_TO_CART),
    body,
  );
  return payload;
}

export async function deleteFromCart(id: string) {
  const {
    data: { payload, messages },
  }: { data: { payload: CartModel; messages: string[] } } = await axios.delete(
    APIUrlGenerator(API_ROUTES.DELETE_FROM_CART(id)),
  );
  return { payload, messages };
}

export async function postVoucher(code: string) {
  const {
    data: { errors, payload },
  }: { data: { errors: ErrorResponseModel; payload: CartModel } } = await axios.post(
    APIUrlGenerator(API_ROUTES.POST_VOUCHER),
    { code },
  );
  return { errors, payload };
}

export async function deleteVoucher(code: string) {
  const {
    data: { messages, payload },
  }: { data: { messages: string[]; payload: CartModel } } = await axios.delete(
    APIUrlGenerator(API_ROUTES.DELETE_VOUCHER(code)),
  );
  return { messages, payload };
}

export async function postCheckout(params: CheckoutParams) {
  const {
    data: { payload },
  }: { data: { payload: any } } = await axios.post(
    APIUrlGenerator(API_ROUTES.POST_CHECKOUT),
    params,
  );
  return payload;
}
