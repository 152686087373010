import { useMutation, useQuery } from '@tanstack/react-query';
import { queryOptions } from '@tanstack/react-query';

import {
  acceptAffiliateRules,
  getAddOnsInfo,
  getCommentList,
  getDownloadLink,
  getSingleProduct,
  postComment,
  postFavorites,
  postRate,
} from '@/components/app/product/services';
import {
  GetAddOnsInfoType,
  GetCommentListType,
  GetDownloadLinkType,
  GetProductType,
  PostCommentType,
  PostFavoritesType,
  PostRateType,
} from '@/components/app/product/services/type';

import {
  GET_ACCEPT_AFFILIATE_RULES,
  GET_ADD_TO_CART_INFO,
  GET_COMMENT_LIST,
  GET_DOWNLOAD_LINK,
  GET_SINGLE_PRODUCT,
  POST_COMMENT,
  POST_FAVORITES,
  POST_RATE,
} from '@/constant/query-key';

export const getSingleProductOptions = (slug?: GetProductType) =>
  queryOptions({
    queryKey: [GET_SINGLE_PRODUCT, slug],
    queryFn: () => getSingleProduct(slug),
  });
export const getSingleProductCommentsOptions = ({ slug, filters, page }: GetCommentListType) =>
  queryOptions({
    queryKey: [GET_COMMENT_LIST, slug, filters, page],
    queryFn: () => getCommentList({ slug, filters, page }),
  });
export function useGetSingleProduct(slug?: GetProductType) {
  return useQuery({
    queryKey: [GET_SINGLE_PRODUCT, slug],
    queryFn: () => getSingleProduct(slug),
  });
}

export function useGetCommentList({ slug, filters, page }: GetCommentListType) {
  return useQuery({
    queryKey: [GET_COMMENT_LIST, slug, filters, page],
    queryFn: () => getCommentList({ slug, filters, page }),
  });
}

export function useGetAddOnsInfo(params: GetAddOnsInfoType) {
  return useQuery({
    queryKey: [GET_ADD_TO_CART_INFO, params],
    queryFn: () => getAddOnsInfo(params),
  });
}

export function useGetDownloadLink(id: GetDownloadLinkType) {
  return useQuery({
    queryKey: [GET_DOWNLOAD_LINK, id],
    queryFn: () => getDownloadLink(id),
    enabled: false,
  });
}

export function usePostComment() {
  return useMutation({
    mutationKey: [POST_COMMENT],
    mutationFn: (body: PostCommentType) => postComment(body),
  });
}

export function useGetAcceptAffiliateRules() {
  return useQuery({
    queryKey: [GET_ACCEPT_AFFILIATE_RULES],
    queryFn: acceptAffiliateRules,
    enabled: false,
    gcTime: 0,
  });
}

export function usePostFavorites() {
  return useMutation({
    mutationKey: [POST_FAVORITES],
    mutationFn: (body: PostFavoritesType) => postFavorites(body),
  });
}

export function usePostRate() {
  return useMutation({
    mutationKey: [POST_RATE],
    mutationFn: (body: PostRateType) => postRate(body),
  });
}
