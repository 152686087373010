import cn from '@/lib/clsxm';

import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import Props from '@/components/shared/components/price-view/discount-label/type';

export default function DiscountLabel({ discount, className }: Props) {
  return (
    <Container
      center
      className={cn(
        'h-9 w-9 rounded-lg bg-[linear-gradient(257.48deg,_#FF9606_4.12%,_#F9BB00_95.45%)] p-2',
        className,
      )}
    >
      <Text bold className='text-base text-[#FFFFFF]'>
        {discount}&#37;
      </Text>
    </Container>
  );
}
