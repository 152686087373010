import { queryOptions, useQuery } from '@tanstack/react-query';

import { getDownloadLink, getSearch, getStoreDiscount } from '@/components/shared/services';
import { getDownloadLinkType, SearchType } from '@/components/shared/services/types';

import { GET_FREE_DOWNLOAD_LINK, GET_SEARCH, GET_STORE_DISCOUNT } from '@/constant/query-key';

export function useGetSearch({ params, enabled }: SearchType) {
  return useQuery({
    queryFn: () => getSearch({ params }),
    queryKey: [GET_SEARCH],
    enabled: enabled,
  });
}

export function useGetFreeDownloadLink(productId: getDownloadLinkType) {
  return useQuery({
    queryFn: () => getDownloadLink(productId),
    queryKey: [GET_FREE_DOWNLOAD_LINK, productId],
    enabled: false,
  });
}

export const getStoreDiscountOptions = (slug: string) =>
  queryOptions({
    queryKey: [GET_STORE_DISCOUNT, slug],
    queryFn: () => getStoreDiscount(slug),
  });
