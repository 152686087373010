import cn from '@/lib/clsxm';
import { priceFormatter } from '@/lib/helper';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import Group from '@/components/@base/input/@shared/group';
import Label from '@/components/@base/input/@shared/label';
import CheckBox from '@/components/@base/input/check-box';
import { Text } from '@/components/@base/typography';
import Props from '@/components/shared/components/add-to-cart-button/add-to-cart-modal/options-item/type';

import localization, { pageLevelLocalization } from '@/constant/localization';
import XImage from '@/components/@base/x-image';
import imagesList from '@/constant/images-list';
import Tooltip from '@/components/@base/tooltip';
import React from 'react';
import { When } from 'react-if';

const { product: productLocalization } = pageLevelLocalization;

export default function OptionsItem({
                                      label,
                                      price,
                                      additionalItems,
                                      hasCheckbox = false,
                                      onChange,
                                      className,
                                      disabled = false,
                                      checked = false,
                                      noSign = false,
                                      showCurrency = true,
                                      finalLastPrice = 0,
                                      discount = 0,
                                      lastPrice = 0,
                                      type,
                                      subTitle
                                    }: Props) {
  const sign = price > 0 ? '+' : price < 0 ? '-' : '';
  return (
    <Container center className={cn('justify-between py-4', className)}>
      <Group className="flex items-center gap-2">
        {hasCheckbox && (
          <CheckBox
            onChange={(checked) => onChange && onChange(checked)}
            disabled={disabled}
            checked={checked}
            className="bg-[#E5E8EB]"
          />
        )}
        <Label className="line-clamp-1 text-sm text-[#5B5C60]">{label}</Label>
        {additionalItems}
        <When condition={subTitle}>
          <Container>
            <XImage
              src={imagesList.icons.infoIcon}
              alt={subTitle}
              width={16}
              height={16}
              data-tooltip-id={'dynamicAddon'}
              data-tooltip-content={subTitle}
            />
            <Tooltip id={'dynamicAddon'} />
          </Container>
        </When>
      </Group>
      <Container center className="gap-[7px]">
        {finalLastPrice > 0 && (
          <Text className="-mb-1 text-[#BCA087] line-through">
            {priceFormatter(Math.abs(price))}
          </Text>
        )}
        {discount > 0 && lastPrice > 0 && (
          <Container
            className="mb-1.5 flex h-[26.5px] w-[26px] items-center justify-center rounded-[5px] border-[1px] border-[#FFFFFF] bg-[linear-gradient(257.48deg,_#FF9606_4.12%,_#F9BB00_95.45%)]  shadow-[0px_4px_12px_-5px_#FF7B016E]">
            <Text bold className="text-xs text-[#FFFFFF]">
              {discount}&#37;
            </Text>
          </Container>
        )}
        <Container>
          {lastPrice > 0 && (
            <Text
              className={cn(
                '-mb-1.5 text-left text-sm font-bold text-[#BFBFBF] line-through ',
                showCurrency && price !== 0 && 'pl-8'
              )}
            >
              {priceFormatter(Math.abs(lastPrice))}
              {!noSign && sign}
            </Text>
          )}
          <Container className="flex items-center gap-1">
            <Text size={SIZE_ENUM.LG} bold className="w-full text-left text-[#424244]">
              {finalLastPrice > 0
                ? priceFormatter(Math.abs(finalLastPrice))
                : priceFormatter(Math.abs(price))}
              {!noSign && sign}
            </Text>
            {showCurrency && price !== 0 && (
              <Text className="text-[13px] text-[#76767C]">{localization.IRCurrency}</Text>
            )}
          </Container>
        </Container>
      </Container>
    </Container>
  );
}
