import { SwitchGroup } from '@headlessui/react';

import Container from '@/components/@base/container';
import Props from '@/components/@base/input/@shared/group/type';

export default function Group({ children, className, ...props }: Props) {
  return (
    <SwitchGroup {...props}>
      <Container className={className}>{children}</Container>
    </SwitchGroup>
  );
}
