import Skeleton from 'react-loading-skeleton';

import cn from '@/lib/clsxm';

import Container from '@/components/@base/container';

export default function Loading({ hasCheckbox = false }) {
  return (
    <Container center className={cn('h-[52px] justify-between', !hasCheckbox && 'px-3')}>
      <Container center className='gap-2'>
        {hasCheckbox && <Skeleton width={20} height={20} />}
        <Skeleton width={120} height={20} />
      </Container>
      <Skeleton width={65} height={20} />
    </Container>
  );
}
