import { Label as HeadlessLabel } from '@headlessui/react';

import cn from '@/lib/clsxm';

import Props from '@/components/@base/input/@shared/label/type';

export default function Label({ children, className, ...props }: Props) {
  return (
    <HeadlessLabel {...props} className={cn('cursor-pointer', className)}>
      {children}
    </HeadlessLabel>
  );
}
