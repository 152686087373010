'use client';

import { useEffect, useState } from 'react';

import Container from '@/components/@base/container';
import { usePostAddToCart } from '@/components/app/cart/services/hooks';
import { useGetAddOnsInfo } from '@/components/app/product/services/hooks';
import OptionsItem from '@/components/shared/components/add-to-cart-button/add-to-cart-modal/options-item';
import OptionsLoading from '@/components/shared/components/add-to-cart-button/add-to-cart-modal/options-item/loading';
import ProductSummary from '@/components/shared/components/add-to-cart-button/add-to-cart-modal/product-summary';
import ProductSummaryLoading from '@/components/shared/components/add-to-cart-button/add-to-cart-modal/product-summary/loading';
import Props, {
  ADDONS_ENUM,
} from '@/components/shared/components/add-to-cart-button/add-to-cart-modal/type';

import { useAddonsStore } from '@/store/addons.store';
import { usePrerequisitesStore } from '@/store/prerequisites.store';

import { pageLevelLocalization } from '@/constant/localization';

const { product: productLocalization } = pageLevelLocalization;

export default function AddToCartModal({ slug, productId, demoId }: Props) {
  const { addons, setAddons } = useAddonsStore();
  const { prerequisites, setPrerequisites } = usePrerequisitesStore();
  const { data, isPending } = useGetAddOnsInfo({ slug, demoId });
  const [finalPrice, setFinalPrice] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const { mutate } = usePostAddToCart();
  const unselectedPrerequisites = Object.keys(prerequisites).filter(
    (id) => !prerequisites[id].selected,
  );
  const [price, setPrice] = useState(0);
  const [lastPrice, setLastPrice] = useState(0);
  useEffect(() => {
    if (data) {
      if (data.addons && data.addons.length > 0) {
        let finalPrice = data.price;
        let finalLastPrice = data.lastPrice;
        let addonSelected = false;

        data.addons.forEach(({ type, price, lastPrice }) => {
          if (type === ADDONS_ENUM.SUBSCRIPTION_LIFETIME && addons[type]?.selected) {
            finalPrice = price + data.price;
            finalLastPrice = lastPrice + data.lastPrice;
            addonSelected = true;
          } else if (type === ADDONS_ENUM.SUBSCRIPTION_ONE_YEAR && addons[type]?.selected) {
            finalPrice = price;
            finalLastPrice = lastPrice;
            addonSelected = true;
          }
        });

        if (addonSelected) {
          setPrice(finalPrice);
          setLastPrice(finalLastPrice);
        } else {
          setPrice(data.price);
          setLastPrice(data.lastPrice);
        }
      } else {
        setPrice(data.price);
        setLastPrice(data.lastPrice);
      }

      let calculatedPrice = data.price;
      let calculatedProductPrice = data.price;
      if (addons[ADDONS_ENUM.SUBSCRIPTION_ONE_YEAR]?.selected) {
        calculatedProductPrice = addons[ADDONS_ENUM.SUBSCRIPTION_ONE_YEAR].price;
        calculatedPrice = addons[ADDONS_ENUM.SUBSCRIPTION_ONE_YEAR].price;
      }
      if (addons[ADDONS_ENUM.REPEAT_PURCHASE]?.selected && data.lastPrice > 0) {
        calculatedPrice = data.lastPrice;
      }

      for (const [key, value] of Object.entries(addons)) {
        if (value.selected && key !== ADDONS_ENUM.SUBSCRIPTION_ONE_YEAR) {
          calculatedPrice += value.price;
        }
      }
      if (Object.keys(prerequisites).length !== 0) {
        for (const prerequisite of Object.values(prerequisites)) {
          if (!prerequisite.selected) {
            calculatedPrice -= prerequisite.price;
            calculatedProductPrice -= prerequisite.price;
          }
        }
        setProductPrice(calculatedProductPrice);
      }

      setFinalPrice(calculatedPrice);
    }
  }, [data, addons]);
  const handleAddOnsChange = (key: string, checked: boolean, price: number) => {
    const updatedAddOns = {
      ...addons,
      [key]: { selected: checked, price: price },
    };
    setAddons(updatedAddOns);
    if (data) {
      const newAddonsList = Object.keys(updatedAddOns).filter(
        (item) => updatedAddOns[item].selected,
      );
      mutate({
        id: productId,
        slug: newAddonsList,
        prerequisite: unselectedPrerequisites,
      });
    }
  };
  return (
    <Container className='bg-[#F0F0F280] p-[30px] pb-[18px]'>
      <Container className='rounded-md bg-[#FFFFFF] p-2 pb-[9px] shadow-[0px_15px_25px_0px_#969BA41A]'>
        <Container className='flex flex-col divide-y divide-dashed divide-[#7E899B33] p-3'>
          {isPending ? (
            <ProductSummaryLoading />
          ) : (
            data && (
              <ProductSummary
                {...data}
                price={price}
                lastPrice={lastPrice}
                finalPrice={Object.keys(prerequisites).length > 0 ? productPrice : 0}
              />
            )
          )}
          {isPending ? (
            <>
              <OptionsLoading hasCheckbox />
              <OptionsLoading hasCheckbox />
            </>
          ) : (
            data &&
            data.addons &&
            data.addons.length > 0 &&
            data.addons.map(
              ({ id, type, title, price, subTitle, lastPrice, discount }) =>
                !(
                  (type === ADDONS_ENUM.SUBSCRIPTION_LIFETIME && price < 0) ||
                  (type === ADDONS_ENUM.SUBSCRIPTION_LIFETIME && addons[type]?.selected) ||
                  (type === ADDONS_ENUM.SUBSCRIPTION_ONE_YEAR && addons[type]?.selected)
                ) && (
                  <OptionsItem
                    key={id}
                    label={title}
                    price={
                      type === ADDONS_ENUM.SUBSCRIPTION_LIFETIME ? data.regularPrice : price
                    }
                    hasCheckbox
                    onChange={(checked) => handleAddOnsChange(type, checked, price)}
                    checked={addons[type]?.selected}
                    disabled={
                      (type !== ADDONS_ENUM.REPEAT_PURCHASE &&
                        addons[ADDONS_ENUM.REPEAT_PURCHASE]?.selected) ||
                      (type === ADDONS_ENUM.REPEAT_PURCHASE &&
                        addons[ADDONS_ENUM.EXTRA_SUPPORT]?.selected) ||
                      (type === ADDONS_ENUM.REPEAT_PURCHASE &&
                        addons[ADDONS_ENUM.INSTALLATION]?.selected)
                    }
                    noSign={
                      type === ADDONS_ENUM.SUBSCRIPTION_LIFETIME ||
                      type === ADDONS_ENUM.SUBSCRIPTION_ONE_YEAR
                    }
                    finalLastPrice={
                      type === ADDONS_ENUM.SUBSCRIPTION_LIFETIME &&
                      data.discount > 0 &&
                      data.isOnSale
                        ? price + data.price
                        : 0
                    }
                    subTitle={subTitle}
                    discount={discount || 0}
                    // lastPrice={type === ADDONS_ENUM.EXTRA_SUPPORT ? lastPrice : 0}
                    lastPrice={lastPrice || 0}
                  />
                ),
            )
          )}
        </Container>
        {isPending ? (
          <OptionsLoading />
        ) : (
          <OptionsItem
            className='rounded-[7px] bg-gradient-to-b from-[#84ba5233]/10 to-[#84ba5200] px-3 pb-1'
            label={productLocalization.payableAmount}
            price={finalPrice}
            noSign
          />
        )}
      </Container>
    </Container>
  );
}
