'use client';
import { queryOptions, useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import Cookies from 'universal-cookie';

import {
  getMenuList,
  getProductRating,
  getProfile,
  getSearchSuggestion,
  getTopbar,
  getUserIP,
  postNewsletter
} from '@/components/layout/services';

import {
  GET_MENU_LIST,
  GET_PRODUCT_RATING,
  GET_PROFILE,
  GET_SEARCH_SUGGESTION,
  GET_TOPBAR,
  GET_USER_IP,
  POST_NEWSLETTER
} from '@/constant/query-key';
import StorageKey from '@/constant/storage-key';
import UserModel from '@/models/user.model';
import { postLogout } from '@/components/shared/services';

export function useGetSearchSuggestion() {
  return useQuery({
    queryFn: () => getSearchSuggestion(),
    queryKey: [GET_SEARCH_SUGGESTION]
  });
}

export function useGetMenuList() {
  return useQuery({
    queryFn: () => getMenuList(),
    queryKey: [GET_MENU_LIST]
  });
}

export function getMenuListOptions() {
  return queryOptions({
    queryFn: () => getMenuList(),
    queryKey: [GET_MENU_LIST]
  });
}

export function useGetProfile() {
  const router = useRouter();
  const cookies = new Cookies(null, {
    path: '/',
    domain: process.env.NEXT_PUBLIC_WEB_TOKEN_DOMAIN
  });

  const { data, error, isLoading } = useQuery({
    queryFn: () => getProfile(),
    queryKey: [GET_PROFILE],
    retry: false,
    onError: (err: Error) => {
      cookies.remove(StorageKey.TOKEN);
      router.push('/');
    }
  } as UseQueryOptions<UserModel | undefined, Error>);

  const logout = async () => {
    cookies.remove(StorageKey.TOKEN);
    cookies.remove('zebline-login');
    if (window.zebline?.user?.logout) {
      window.zebline.user.logout();
    }
    window.location.reload();
   await postLogout()
  };

  return {
    profile: data,
    isLoading,
    isAuthenticated: !!data,
    logout
  };
}

export function usePostNewsletter() {
  return useMutation({
    mutationFn: (email: string) => postNewsletter(email),
    mutationKey: [POST_NEWSLETTER]
  });
}

export function useGetTopbar() {
  return useQuery({
    queryFn: () => getTopbar(),
    queryKey: [GET_TOPBAR]
  });
}

export function useGetProductRating() {
  return useQuery({
    queryFn: () => getProductRating(),
    queryKey: [GET_PRODUCT_RATING],
    enabled: false
  });
}

export function useGetUserIP() {
  return useQuery({
    queryFn: () => getUserIP(),
    queryKey: [GET_USER_IP],
    enabled: false
  });
}
