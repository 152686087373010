import axios from '@/lib/axios-instance';
import { getCookie, setCookie } from '@/lib/cookies'; // export async function getServiceList(params?: GetServiceListType) {
import { APIUrlGenerator, buildGuestToken } from '@/lib/helper';

import { API_ROUTES } from '@/constant/routes';
import { UNAUTHORIZED } from '@/constant/status-codes';
import StorageKey from '@/constant/storage-key';
import ErrorResponseModel from '@/models/error-response.model'; // export async function getServiceList(params?: GetServiceListType) {
import InvoiceModel from '@/models/invoice.model';
import MenuModel from '@/models/menu.model';
import SearchSuggestionModel from '@/models/search-suggestion.model';
import TopbarModel from '@/models/topbar.model';
import UserModel from '@/models/user.model';
import { UserIp } from '@/components/layout/services/type';

// export async function getServiceList(params?: GetServiceListType) {
//   const { data }: { data: ServiceModel[] } = await axios.get(
//     APIUrlGenerator(API_ROUTES.GET_SERVICES, params),
//   );
//   return data;
// }

export async function getSearchSuggestion() {
  const {
    data: { payload }
  }: { data: { payload: SearchSuggestionModel } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_SEARCH_SUGGESTION)
  );
  return payload;
}

export async function getMenuList() {
  const {
    data: { payload }
  }: { data: { payload: MenuModel } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_MENU_LIST)
  );
  return payload;
}

export async function getProfile() {
  const isGuest = getCookie(StorageKey.TOKEN)?.includes('guest_');
  if (isGuest) {
    return;
  }
  try {
    const {
      data: { payload, status },
    }: { data: { payload: UserModel; status: number } } = await axios.get(
      APIUrlGenerator(API_ROUTES.GET_PROFILE),
    );
    return payload;
  } catch (e: any) {
    if (e.response?.status == UNAUTHORIZED) {
      const token = getCookie(StorageKey.TOKEN);
      if (!token.includes('guest_')) {
        setCookie(StorageKey.TOKEN, buildGuestToken());
      }
      return;
      // router.push('/login'); // Redirect to login page
    }
  }
}

export async function postNewsletter(email: string) {
  const {
    data: { messages, errors },
  }: {
    data: { messages: string[]; errors: ErrorResponseModel };
  } = await axios.post(APIUrlGenerator(API_ROUTES.POST_NEWSLETTER), { email });
  return { messages, errors };
}

export async function getTopbar() {
  const {
    data: { payload }
  }: { data: { payload: TopbarModel } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_SETTINGS('topBarInfo'))
  );
  return payload;
}

export async function getProductRating() {
  const {
    data: { payload }
  }: { data: { payload: InvoiceModel[] } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_PRODUCT_RATING)
  );
  return payload;
}

export async function getUserIP() {
  const {
    data
  }: { data: UserIp } = await axios.get(APIUrlGenerator(API_ROUTES.GET_USER_IP)
  );
  return data;
}
