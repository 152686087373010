import Skeleton from 'react-loading-skeleton';

import Container from '@/components/@base/container';

export default function Loading() {
  return (
    <Container center className='justify-between gap-2 py-4'>
      <Container center className='gap-[13px]'>
        <Skeleton width={60} height={60} className='rounded-xl' />

        <Container>
          <Skeleton width={80} />
          <Skeleton width={60} />
        </Container>
      </Container>

      <Skeleton width={100} height={40} />
    </Container>
  );
}
