import { create } from 'zustand';

import { SelectedAddOns } from '@/components/shared/components/add-to-cart-button/add-to-cart-modal/type';

interface AddonsStore {
  addons: SelectedAddOns;
  setAddons: (addons: SelectedAddOns) => void;
}

export const useAddonsStore = create<AddonsStore>((set) => ({
  addons: {},
  setAddons: (addons: SelectedAddOns) => set({ addons }),
}));
