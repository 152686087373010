import { create } from 'zustand';

interface SelectedPrerequisitesType {
  [key: string]: { selected: boolean; price: number };
}

interface PrerequisitesStore {
  prerequisites: SelectedPrerequisitesType;
  setPrerequisites: (addons: SelectedPrerequisitesType) => void;
}

export const usePrerequisitesStore = create<PrerequisitesStore>((set) => ({
  prerequisites: {},
  setPrerequisites: (prerequisites: SelectedPrerequisitesType) => set({ prerequisites }),
}));
