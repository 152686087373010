import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';

import {
  deleteFromCart,
  deleteVoucher,
  getCart,
  postAddToCart,
  postCheckout,
  postVoucher,
} from '@/components/app/cart/services';
import { AddToCartTypes, CheckoutParams } from '@/components/app/cart/services/type';

import {
  DELETE_FROM_CART,
  DELETE_VOUCHER,
  GET_CART,
  POST_CHECKOUT,
  POST_VOUCHER,
} from '@/constant/query-key';
import CartModel from '@/models/cart.model';

useGetCart;

export function useGetCart(options?: any): UseQueryResult<CartModel> {
  return useQuery<CartModel>({
    queryFn: () => getCart(),
    queryKey: [GET_CART],
    ...options,
  });
}

export function usePostAddToCart() {
  return useMutation({
    mutationFn: (body: AddToCartTypes) => postAddToCart(body),
    mutationKey: [GET_CART],
  });
}

export function useDeleteFromCart() {
  return useMutation({
    mutationFn: (id: string) => deleteFromCart(id),
    mutationKey: [DELETE_FROM_CART],
  });
}

export function usePostVoucher() {
  return useMutation({
    mutationFn: (code: string) => postVoucher(code),
    mutationKey: [POST_VOUCHER],
  });
}

export function useDeleteVoucher() {
  return useMutation({
    mutationFn: (code: string) => deleteVoucher(code),
    mutationKey: [DELETE_VOUCHER],
  });
}

export function usePostCheckout() {
  return useMutation({
    mutationFn: (params: CheckoutParams) => postCheckout(params),
    mutationKey: [POST_CHECKOUT],
  });
}
