import { Tooltip as ReactTooltip } from 'react-tooltip';

import cn from '@/lib/clsxm';

import Props from '@/components/@base/tooltip/type';

export default function Tooltip({ className, ...props }: Props) {
  return (
    <ReactTooltip
      {...props}
      className={cn(
        '!rounded-[5px] max-w-[300px] !border !border-white !bg-[#464E58] !text-[12px] !font-bold !text-[#E5E8EB] !shadow-[0px_5px_15px_0px_rgba(0,0,0,0.15)] z-10',
        className,
      )}
    />
  );``
}
