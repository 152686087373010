import { Switch } from '@headlessui/react';
import { IoMdCheckmark } from 'react-icons/io';

import cn from '@/lib/clsxm';

import Container from '@/components/@base/container';
import Props from '@/components/@base/input/check-box/type';
import { Text } from '@/components/@base/typography';

import { ICON_COLOR } from '@/constant/icon-size-color';

export default function CheckBox({
  onChange,
  checked = false,
  className,
  text,
  ...props
}: Props) {
  return (
    <Switch checked={checked} onChange={(checked) => onChange && onChange(checked)} {...props}>
      <Container className={cn(text && 'flex items-center gap-2.5')}>
        <Container
          className={cn(
            'border-i-gray flex h-[18px] w-[18px] items-center justify-center rounded border-2',
            checked &&
              'border-none bg-[linear-gradient(247.65deg,_#FFC107_-35.57%,_#FF9737_100%)]',
            className,
          )}
        >
          {checked && <IoMdCheckmark color={ICON_COLOR.white} />}
        </Container>
        {text && <Text bold>{text}</Text>}
      </Container>
    </Switch>
  );
}
