import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import XImage from '@/components/@base/x-image';
import Props from '@/components/shared/components/add-to-cart-button/add-to-cart-modal/product-summary/type';
import PriceView from '@/components/shared/components/price-view';

export default function ProductSummary({
  icon,
  title,
  storeName,
  price,
  lastPrice,
  discount,
  saleEndedAt,
  finalPrice = 0,
  isOnSale,
}: Props) {
  const finalDiscount = isOnSale ? discount : 0;

  return (
    <Container center className='justify-between gap-2 py-4'>
      <Container center className='gap-[13px]'>
        <XImage src={icon.publicLink} alt={icon.title} width={60} height={60} />

        <Container>
          <Text className='line-clamp-2'>{title}</Text>
          <Text className='text-[13px] text-[#76767C]'>{storeName}</Text>
        </Container>
      </Container>

      <PriceView
        price={finalPrice ? finalPrice : price}
        lastPrice={lastPrice}
        discount={finalDiscount}
        saleEndedAt={saleEndedAt}
        priceWrapperClassName='flex gap-[5px]'
        regularPrice={lastPrice}
      />
    </Container>
  );
}
